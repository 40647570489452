import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { PrismicRichText, SliceZone } from '@prismicio/react'
import { IntenseText } from 'components/design'
import { ImageGrid, Rimg } from 'components/images'
import { Root } from 'templates/layout'
import InnerNav from 'templates/about/inner-nav'
import RightArrow from 'svg/right-arrow-thick'
import { ReadyToStart } from 'templates/home/slides/ready-to-start'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const CaseStudyPageTpl = (props) => {
	const {
		anchors,
		architect,
		gridToDisplay,
		embedGridSlices,
		hero,
		location,
		nextCaseStudy,
		project_name,
		slices,
	} = props.pageContext || props

	useEffect(() => {
		if (window.innerWidth >= 1024) {
			const stickyHeight =
				document.querySelector('#bottom-sticky-panel')?.clientHeight || 70

			document.querySelector(
				'#r2s-container'
			).style.marginBottom = `-${stickyHeight}px`
		}

		gsap.set('#ready-to-start-wrapper', { autoAlpha: 1 }, 0)
		const cubeSt = ScrollTrigger.create({
			trigger: '#r2s-container',
			start: 'top bottom',
			end: '+=150% bottom',
			// markers: true,
			onEnter: () => {
				gsap.getById('infinite-cube-tl').resume()
			},
			onLeaveBack: () => {
				gsap.getById('infinite-cube-tl').pause()
			},
		})

		return () => {
			cubeSt.kill()
		}
	})

	return (
		<Root
			desktopWordmarkPosition="sliding"
			desktopWordmarkColor="text-white"
			desktopCtaVariant="hidden"
			mainFullWidth
			hero={hero ?? null}
		>
			<InnerNav innerLinks={anchors} contentWidth firstTitle={'Intro'} />
			<SliceZone
				slices={slices}
				components={{
					horizontal_image: HorizontalImage,
					image_pair: ImagePair,
					render_grid: RenderGrid,
					review: Review,
					services_provided: Provided,
					team: Team,
					text_block: TextBlock,
					top_section: TopSection,
					_video: EmbedFromYoutube,
					embed_youtube: GridWithEmbedYT,
				}}
				context={{
					top: { project_name, location, architect },
					gridToDisplay,
					embedGridSlices,
				}}
			/>

			<section
				id="next-case-study"
				className="relative mt-30 h-screen w-full bg-black/75 text-white"
			>
				<Link
					to={nextCaseStudy.link}
					className="peer group absolute bottom-screen-step-2 z-[2] w-full px-screen-step lg:text-center"
				>
					<h5>Next case study</h5>
					<div className="mt-7 inline-flex items-center font-headings text-4xl font-semibold leading-none lg:justify-center lg:text-6xl lg:leading-none">
						<span className="mr-4">{nextCaseStudy.project_name}</span>
						<i className="block h-10 w-10 -rotate-45 transition-transform duration-300 ease-in-out group-hover:rotate-0 lg:h-12 lg:w-12">
							<RightArrow />
						</i>
					</div>
				</Link>
				<Rimg
					image={nextCaseStudy.image}
					position={36}
					mobilePosition={16}
					className="h-screen w-full object-cover lg:peer-hover:brightness-[0.6]"
				/>
			</section>
			<section className="relative h-screen w-full" id="r2s-container">
				<ReadyToStart />
			</section>
		</Root>
	)
}

const RenderGrid = ({
	context: { gridToDisplay },
	slice: {
		primary: { anchor, heading },
	},
}) => (
	<>
		<div id={anchor || null} className="mt-20 px-screen-step lg:mt-36 lg:text-center">
			{heading.richText?.length ? (
				<PrismicRichText field={heading.richText} />
			) : null}
		</div>
		<ImageGrid grid={gridToDisplay} keepImageHeight />
	</>
)

const TopSection = ({
	context: {
		top: { architect, location, project_name },
	},
	slice: {
		primary: { intro },
	},
}) => (
	<>
		{/* TODO: set proper width to section on desktop with mx-auto */}
		<section className="mt-10 flex flex-wrap justify-center px-screen-step lg:flex-nowrap">
			<h1 className="w-full lg:w-screen-step-8">{project_name}</h1>
			{/* To align h1 with text by baseline, compute difference in line heights and multiply by .9 to ignore bottom parts */}
			{/* It also does spacing work on mobile, thus no need to override it*/}
			<IntenseText className="w-full pt-[calc(0.9*(3.5rem-1.25*1.625rem))] lg:ml-screen-step-2 lg:w-screen-step-8">
				<PrismicRichText field={intro.richText} />
			</IntenseText>
		</section>
		<section className="publication -mb-20 flex flex-wrap justify-center px-screen-step text-pale lg:mt-10 lg:flex-nowrap">
			<p className="block w-full lg:w-screen-step-8">
				<strong className="uppercase">Location: </strong>
				{location}
			</p>
			<p className="!mt-2 block w-full lg:!mt-0 lg:ml-screen-step-2 lg:w-screen-step-8">
				<strong className="uppercase">Partner: </strong>
				{architect}
			</p>
		</section>
	</>
)

const TextBlock = ({
	slice: {
		primary: { anchor, text },
	},
}) => (
	<section
		id={anchor || null}
		className="publication mx-auto mt-30 w-full max-w-screen-xl px-screen-step lg:w-screen-step-18 lg:px-0"
	>
		<PrismicRichText field={text.richText} />
	</section>
)

const Provided = ({
	slice: {
		primary: { anchor, heading, services },
	},
}) => (
	<section
		id={anchor || null}
		className="mx-auto mt-30 flex w-full flex-wrap justify-center bg-black/5 py-10 px-screen-step lg:w-screen-step-26 lg:px-0 lg:py-18"
	>
		<div className="w-full lg:w-screen-step-8">
			<PrismicRichText field={heading.richText} />
		</div>
		<div className="publication w-full lg:ml-screen-step-2 lg:-mt-5 lg:w-screen-step-8">
			<PrismicRichText field={services.richText} />
		</div>
	</section>
)

const Team = ({
	slice: {
		items,
		primary: { anchor, heading },
	},
}) => (
	<section
		id={anchor || null}
		className="mt-30 px-screen-step lg:mx-auto lg:w-screen-step-18 lg:px-0"
	>
		<PrismicRichText field={heading.richText} />

		<div className="mt-10 grid gap-y-6 lg:grid-cols-2 lg:gap-x-screen-step-2">
			{items.map(
				({
					member: {
						document: {
							data: { name, position, square_funny_photo },
						},
					},
					position_override,
				}) => (
					<div className="flex w-screen-step-8" key={name}>
						<Rimg
							image={square_funny_photo}
							position={2}
							mobilePosition={4}
							className="flex-none"
						/>
						<div className="ml-10 flex-none">
							<IntenseText>{name}</IntenseText>
							<IntenseText className="mt-1 font-normal">
								{position_override || position}
							</IntenseText>
						</div>
					</div>
				)
			)}
		</div>
	</section>
)

const Review = ({
	slice: {
		primary: { anchor, heading, author_name, author_position, logo, text },
	},
}) => (
	<section
		id={anchor || null}
		className="mx-auto mt-30 w-full px-screen-step lg:w-screen-step-22 lg:px-0"
	>
		<div className="lg:ml-screen-step-2">
			<PrismicRichText field={heading.richText} />
		</div>

		<div className="relative mt-10 bg-black/5 px-screen-step py-20 lg:px-screen-step-4">
			<h3 className="!leading-none">{author_name}</h3>
			<h4 className="mt-2">{author_position}</h4>

			<div className="publication mt-8">
				<PrismicRichText field={text.richText} />
			</div>

			{logo?.url ? (
				<Rimg
					image={logo}
					position={2}
					mobilePosition={4}
					className="absolute top-screen-step-1/4 right-screen-step"
				/>
			) : null}
		</div>
	</section>
)

const HorizontalImage = ({
	slice: {
		primary: { image },
	},
}) => (
	<Rimg
		image={image}
		position={26}
		mobilePosition={14}
		className="mx-auto mt-10 lg:mt-14"
	/>
)

const ImagePair = ({
	slice: {
		primary: { image_1, image_2 },
	},
}) => (
	<section className="mx-auto mt-10 flex w-full px-screen-step lg:mt-14 lg:w-screen-step-26 lg:px-0">
		<Rimg image={image_1} position={12} mobilePosition={6} />
		<Rimg
			image={image_2}
			position={12}
			mobilePosition={6}
			className="ml-screen-step-2"
		/>
	</section>
)

const EmbedFromYoutube = ({
	slice: {
		primary: {
			video_url: { embed_url },
			image: { url },
		},
	},
}) => {
	const imageStart = React.createRef(),
		playerYT = React.createRef()

	function showEmbed() {
		imageStart.current.classList.add('opacity-0', 'pointer-events-none')
		playerYT.current.src += '?autoplay=1'
	}

	return (
		<section className="mx-auto mt-14 flex w-full px-screen-step lg:w-screen-step-26 lg:px-0">
			<div
				className={'w-full'}
				style={{
					position: 'relative',
					paddingBottom: '56.25%',
					height: 0,
				}}
			>
				<div
					ref={imageStart}
					id="frameStart"
					className={
						'absolute top-0 left-0 z-10 my-0 mx-auto h-full w-full transition-opacity duration-300'
					}
				>
					<div className={'h-full bg-black'}>
						<img
							className={'h-full w-full object-cover opacity-70'}
							src={url}
							alt="Embed start"
						/>
					</div>
					<button
						className={
							'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white transition-colors duration-300 hover:text-accent'
						}
						type="button"
						onClick={showEmbed}
					>
						<svg
							width="90"
							height="90"
							viewBox="0 0 190 190"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M150.559 94.5L9 173.656V15.344L150.559 94.5Z"
								stroke="currentColor"
								stroke-width="18"
							/>
						</svg>
					</button>
				</div>
				<iframe
					style={{
						margin: '0 auto',
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}
					ref={playerYT}
					src={`https://www.youtube.com/embed/${embed_url.split('=')[1]}`}
					title="YouTube video player"
					frameBorder="0"
					allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
					allowFullScreen
				></iframe>
			</div>
		</section>
	)
}

const GridWithEmbedYT = ({
	context: { embedGridSlices },
	slice: {
		primary: {
			video_url: { embed_url },
			image: { url },
			anchor,
			heading,
		},
	},
}) => {
	const imageStart = React.createRef(),
		playerYT = React.createRef()

	function showEmbed() {
		imageStart.current.classList.add('opacity-0', 'pointer-events-none')
		playerYT.current.src += '?autoplay=1'
	}

	return (
		<>
			<div
				id={anchor || null}
				className="mt-20 px-screen-step lg:mt-36 lg:text-center"
			>
				{heading.richText?.length ? (
					<PrismicRichText field={heading.richText} />
				) : null}
			</div>
			<div className="-mb-6 mt-6 px-screen-step-1/2 pb-screen-step-1/2 lg:-mb-16 lg:mt-16">
				<div
					className={'w-full '}
					style={{
						position: 'relative',
						paddingBottom: '56.25%',
						height: 0,
					}}
				>
					<div
						ref={imageStart}
						id="frameStart"
						className={
							'absolute top-0 left-0 z-10 my-0 mx-auto h-full w-full transition-opacity duration-300'
						}
					>
						<div className={'h-full bg-black'}>
							<img
								className={'h-full w-full object-cover opacity-70'}
								src={url}
								alt="Embed start"
							/>
						</div>
						<button
							className={
								'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white transition-colors duration-300 hover:text-accent'
							}
							type="button"
							onClick={showEmbed}
						>
							<svg
								width="90"
								height="90"
								viewBox="0 0 190 190"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M150.559 94.5L9 173.656V15.344L150.559 94.5Z"
									stroke="currentColor"
									stroke-width="18"
								/>
							</svg>
						</button>
					</div>
					<iframe
						style={{
							margin: '0 auto',
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
						}}
						ref={playerYT}
						src={`https://www.youtube.com/embed/${embed_url.split('=')[1]}`}
						title="YouTube video player"
						frameBorder="0"
						allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
						allowFullScreen
					></iframe>
				</div>
			</div>

			<ImageGrid grid={embedGridSlices} keepImageHeight />
		</>
	)
}

export default CaseStudyPageTpl
